.center-high {
    background: #{linear-gradient(rgba($lp-color-primary-card, 0.7), rgba($lp-color-primary-card, 0.2))};

    // background: #{radial-gradient(rgba($lp-color-action-3, 0.2), rgba($lp-color-primary-card, 0.9))};
    // background: #{linear-gradient(rgba($lp-color-action-3, 0.7), rgba($lp-color-action-3, 0.2))};
    border-radius: 50%;
    padding: 22px;
    width: 200px;

    // width: 70vw;
    height: 200px;

    // height: 70vw;
    max-width: 70vw;
    max-height: 70vw;
    border-style: inset;
    border-width: 6px;

    // border-color: var(--lp-color-alternate-70);

    // border-color: var(--lp-border-color);
    margin: auto;
}

.plate-border-color-high {
    border-color: var(--lp-border-color-high);
}

.plate-border-color {
    border-color: var(--lp-border-color);
}

.full-high {
    background: linear-gradient(var(--lp-color-primary-70), var(--lp-color-primary-20));
}
