:root {
    // overrides
    --ion-toolbar-background: #{linear-gradient(to right, rgba($lp-color-bg, 0.6), rgba($lp-color-bg, 0.4), rgba($lp-color-bg, 0.6))};
    --ion-background-color: var(--lp-color-bg);
    --ion-text-color: var(--lp-color-primary-text);

    .toolbar-background {
        background: transparent;
        border-color: transparent;
    }

    .inner-scroll {
        background: transparent;
    }

    ion-app.platform-ios12 ion-content {
        pointer-events: auto; /*fix scroll lock on ios12.2*/
    }

    .ion-split-pane-bg {
        background: var(--ion-background-color) !important;
        background-color: var(--ion-background-color) !important;
    }

    .ion-content-gradient {
        // --background: var(--background-radial-gradient-matte-dark);
        --background: var(--lp-color-bg-dark) !important;

        // --background: blue;
   
    }

    .ion-header-gradient {
        // --background: var(--background-radial-gradient-matte-dark);
        --background: var(--lp-color-bg-dark) !important;
    }

    ion-header > ion-toolbar:first-of-type {
        padding-top: 0px;
    }

    --ion-toolbar-min-height: 50px;

    ion-toolbar {
        --min-height: 50px;
        height: 50px;

        // border-bottom: var(--lp-border-width) solid var(--lp-color-primary-item);
   
    }

    ion-item {
        --background: rgba(0,0,0,0);
        --border-color: rgba(0,0,0,0);
        --padding-start: var(--lp-item-padding);
        --inner-padding-end: var(--lp-item-padding);
    }

    ion-list {
        background: rgba(0, 0, 0, 0);
    }

    .ion-label-input {
        width: 50%;
        max-width: 100% !important;
        // --background: rgba(0,0,0,0);
    }

    .ion-label-input-s {
        width: 25%;
        min-width: 100px;
        max-width: 100% !important;
        // --background: rgba(0,0,0,0);
    }

    // ion-col {
    //     padding: 2px;
    // }

    ion-title {
        font-weight: bold;
    }

    ion-fab-button {
        --background: none;
        --box-shadow: none;
        margin: var(--lp-item-padding);
    }

    ion-button {
        --box-shadow: none;
        --border-radius: var(--border-radius-inner-button);
        --border-color: var(--lp-border-color-item);

        // border-style: solid !important;
        // --border-width: var(--border-outset-button) !important;
        --border-width: 0px !important;
        font-weight: bold;
    }

    ion-button.button-disabled {
        opacity: 0.4;
    }

    ion-content {
        overflow: hidden;
        --overflow: hidden;
    }

    ion-checkbox {
        --background: rgba(0,0,0,0);
        --background-checked: rgba(0,0,0,0);
        --border-color: #{rgba($lp-color-primary-text, 0.5) !important};
        --border-color-checked: var(--lp-color-primary-text) !important;
        --checkmark-color: var(--lp-color-primary-text) !important;
        --border-radius: 50%;
    }

    ion-radio {
        --color: #{rgba($lp-color-primary-text, 0.5) !important};
        --color-checked: var(--lp-color-primary-text) !important;
    }

    ion-toggle {
        --background: #{rgba($lp-color-primary-text, 0.5) !important};
        --background-checked: var(--lp-color-primary-text) !important;
        --handle-background: #{rgba($lp-color-primary-text, 0.5) !important};
        --handle-background-checked: var(--lp-color-primary-text) !important;
    }
}
