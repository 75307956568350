
.mat-column-first {
    display: flex;
    justify-content: flex-start;
    padding-left: 5px !important;
}

.mat-column-last {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px !important;
}

// mat-cell:last-of-type {
//     padding-left: 0px;
//     padding-right: 0px;
// }