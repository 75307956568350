// ion-app, ion-nav, ion-tab, ion-tabs, .app-root, .ion-page {
//     &.introjs-fixParent {
//         contain: layout size style !important;
//     }
// }

// .introjs-fixParent {
//     ion-content {
//         position: absolute !important;
//         contain: layout size style !important;
//     }
// }

.introjs-helperLayer {
    background: transparent;
    position: absolute;
}

.introjs-overlay {
    opacity: 0 !important;
}

.introjs-helperLayer:before {
    opacity: 0;
    content: '';
    position: fixed;
    width: inherit;
    height: inherit;
    border-radius: var(--border-radius-global);
    box-shadow: 0 0 0 1000em rgba(0, 0, 0, 0.7);
    opacity: 1;
}

.introjs-helperLayer:after {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
}

.introjs-overlay {
    position: absolute;
    box-sizing: content-box;
    z-index: 999999;
    background-color: #000;
    opacity: 0;
    background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(0, 0, 0, 0.4)), color-stop(100%, rgba(0, 0, 0, 0.9)));
    background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
    background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
    background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
    background: radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity = 50);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.introjs-fixParent {
    // z-index: auto !important;
    opacity: 1.0 !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
}

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
    z-index: 9999999 !important;
}

.introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: white;
    opacity: 0;
    filter: alpha(opacity = 0);
}

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
    position: relative;
}

.introjs-helperLayer {
    box-sizing: content-box;
    position: absolute;
    z-index: 9999998;

    // background-color: #FFF;
    // background-color: rgba(255, 255, 255, 0.9);
    background-color: transparent;

    // border: 1px solid #777;
    // border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: var(--border-radius-global);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.introjs-tooltipReferenceLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -o-box-sizing: content-box;
    box-sizing: content-box;
}

.introjs-helperNumberLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    top: -16px;
    left: -16px;
    z-index: 9999999999 !important;
    padding: 2px;
    font-family: Arial, verdana, tahoma;
    font-size: 13px;
    font-weight: bold;
    color: white;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    background: #ff3019; /* Old browsers */
    background: -webkit-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* Chrome10+,Safari5.1+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff3019), color-stop(100%, #cf0404)); /* Chrome,Safari4+ */
    background: -moz-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* FF3.6+ */
    background: -ms-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* IE10+ */
    background: -o-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* Opera 11.10+ */
    background: linear-gradient(to bottom, #ff3019 0%, #cf0404 100%); /* W3C */
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 3px solid white;
    border-radius: 50%;
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0)"; /* IE6-9 */
    filter: "progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)"; /* IE10 text shadows */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}


.introjs-arrow {
    border: 8px solid transparent;
    content: '';
    position: absolute;
}

.introjs-arrow.top {
    top: -16px;
    border-bottom-color: var(--lp-color-primary-text);
}

.introjs-arrow.top-right {
    top: -16px;
    right: 16px;
    border-bottom-color: var(--lp-color-primary-text);
}

.introjs-arrow.top-middle {
    top: -16px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: var(--lp-color-primary-text);
}

.introjs-arrow.right {
    right: -16px;
    top: 16px;
    border-left-color: var(--lp-color-primary-text);
}

.introjs-arrow.right-bottom {
    bottom: 16px;
    right: -16px;
    border-left-color: var(--lp-color-primary-text);
}

.introjs-arrow.bottom {
    bottom: -16px;
    border-top-color: var(--lp-color-primary-text);
}

.introjs-arrow.bottom-right {
    bottom: -16px;
    right: 16px;
    border-top-color: var(--lp-color-primary-text);
}

.introjs-arrow.bottom-middle {
    bottom: -16px;
    left: 50%;
    margin-left: -5px;
    border-top-color: var(--lp-color-primary-text);
}

.introjs-arrow.left {
    left: -16px;
    top: 16px;
    border-right-color: var(--lp-color-primary-text);
}

.introjs-arrow.left-bottom {
    left: -16px;
    bottom: 16px;
    border-right-color: var(--lp-color-primary-text);
}

.introjs-tooltip {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    padding: 20px;
    // background-color: white;
    background: #{linear-gradient(rgba($lp-color-negative-bg, 0.8), rgba($lp-color-negative-bg, 0.5))};
    min-width: 200px;
    max-width: 300px;
    border-radius: var(--border-radius-global);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
    -webkit-transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -ms-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    transition: opacity 0.1s ease-out;
}

.introjs-tooltipbuttons {
    margin-top: 16px;
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
.introjs-button {
    // box-sizing: content-box;
    // position: relative;
    // overflow: visible;
    // display: inline-block;
    // padding: 0.3em 0.8em;
    // border: 1px solid #d4d4d4;
    // margin: 0;
    // text-decoration: none;
    // text-shadow: 1px 1px 0 #fff;
    // // font: 11px/normal sans-serif;
    // font-size: var(--font-size-m);
    // color: #333;
    // white-space: nowrap;
    // cursor: pointer;
    // outline: none;
    // background-color: #ececec;
    // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4f4), to(#ececec));
    // background-image: -moz-linear-gradient(#f4f4f4, #ececec);
    // background-image: -o-linear-gradient(#f4f4f4, #ececec);
    // background-image: linear-gradient(#f4f4f4, #ececec);
    // -webkit-background-clip: padding;
    // -moz-background-clip: padding;
    // -o-background-clip: padding-box;

    // __<<ngThemingMigrationEscapedComment10>>__

    // __<<ngThemingMigrationEscapedComment11>>__
    // -webkit-border-radius: 0.2em;
    // -moz-border-radius: 0.2em;
    // border-radius: 0.2em;

    // __<<ngThemingMigrationEscapedComment12>>__
    // zoom: 1;
    // *display: inline;
    // margin-top: 10px;

    cursor: pointer;
    padding: 0.3em 0.8em;
    // height: var(--action-button-height);
    width: 100%;
    // margin-left: auto;
    // margin-right: auto;
    margin-left: 4px;
    margin-right: 4px;
    border-color: var(--lp-color-primary);
    border-radius: 12px;
    // border-style: outset;
    border-width: 2px;
    // background: #{radial-gradient(rgba($lp-color-primary, var(--button-grad-1)), rgba($lp-color-primary, var(--button-grad-1)), rgba($lp-color-primary, var(--button-grad-2)))};
    // border-color: var(--lp-border-color-item);
    color: var(--lp-color-primary-text);
}

.introjs-button:hover {
    // border-color: #bcbcbc;
    // text-decoration: none;
    // box-shadow: 0px 1px 1px #e3e3e3;
}

.introjs-button:focus,
.introjs-button:active {
    // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ececec), to(#f4f4f4));
    // background-image: -moz-linear-gradient(#ececec, #f4f4f4);
    // background-image: -o-linear-gradient(#ececec, #f4f4f4);
    // background-image: linear-gradient(#ececec, #f4f4f4);
}

/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.introjs-skipbutton {
    // box-sizing: content-box;
    // margin-right: 5px;
    // color: #7a7a7a;
}

.introjs-prevbutton {
    // -webkit-border-radius: 0.2em 0 0 0.2em;
    // -moz-border-radius: 0.2em 0 0 0.2em;
    // border-radius: 0.2em 0 0 0.2em;
    // border-right: none;
}

.introjs-prevbutton.introjs-fullbutton {
    // border: 1px solid #d4d4d4;
    // -webkit-border-radius: 0.2em;
    // -moz-border-radius: 0.2em;
    // border-radius: 0.2em;
}

.introjs-nextbutton {
    // -webkit-border-radius: 0 0.2em 0.2em 0;
    // -moz-border-radius: 0 0.2em 0.2em 0;
    // border-radius: 0 0.2em 0.2em 0;
}

.introjs-nextbutton.introjs-fullbutton {
    // -webkit-border-radius: 0.2em;
    // -moz-border-radius: 0.2em;
    // border-radius: 0.2em;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
    color: #9a9a9a;
    // border-color: #d4d4d4;
    // box-shadow: none;
    // cursor: default;
    // background-color: #f4f4f4;
    // background-image: none;
    // text-decoration: none;
}

.introjs-hidden {
    display: none;
}

.introjs-tooltiptext {
    font-weight: normal;
    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-m);
    color: var(--lp-color-primary-text) !important;
}

.introjs-bullets {
    text-align: center;
}

.introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 15px auto 0;
    padding: 0;
    display: inline-block;
}

.introjs-bullets ul li {
    box-sizing: content-box;
    list-style: none;
    float: left;
    margin: 0 2px;
}

.introjs-bullets ul li a {
    box-sizing: content-box;
    display: block;
    width: 6px;
    height: 6px;
    background: #ccc;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
}

.introjs-bullets ul li a:hover {
    background: #999;
}

.introjs-bullets ul li a.active {
    background: #999;
}

.introjs-progress {
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px 0 5px 0;
    border-radius: 4px;
    background-color: #ecf0f1;
}

.introjs-progressbar {
    box-sizing: content-box;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    // background-color: #08c;
    background-color: var(--lp-color-primary-text);
}

.introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
}

.introjs-fixedTooltip {
    position: fixed;
}

.introjs-hint {
    box-sizing: content-box;
    position: absolute;
    background: transparent;
    width: 20px;
    height: 15px;
    cursor: pointer;
}

.introjs-hint:focus {
    border: 0;
    outline: 0;
}

.introjs-hidehint {
    display: none;
}

.introjs-fixedhint {
    position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, 0.27);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: rgba(136, 136, 136, 0.24);
    z-index: 10;
    position: absolute;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.introjs-hint-no-anim .introjs-hint-dot {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
}

.introjs-hint-dot {
    box-sizing: content-box;
    border: 10px solid rgba(146, 146, 146, 0.36);
    background: transparent;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    -webkit-animation: introjspulse 3s ease-out;
    -moz-animation: introjspulse 3s ease-out;
    animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0;
}

@-webkit-keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }

    25% {
        -webkit-transform: scale(0);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.1);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.5);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

@-moz-keyframes introjspulse {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }

    25% {
        -moz-transform: scale(0);
        opacity: 0.1;
    }

    50% {
        -moz-transform: scale(0.1);
        opacity: 0.3;
    }

    75% {
        -moz-transform: scale(0.5);
        opacity: 0.5;
    }

    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
}

@keyframes introjspulse {
    0% {
        transform: scale(0);
        opacity: 0.0;
    }

    25% {
        transform: scale(0);
        opacity: 0.1;
    }

    50% {
        transform: scale(0.1);
        opacity: 0.3;
    }

    75% {
        transform: scale(0.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 0.0;
    }
}
