.logo {
    padding: 5px;

    // font-style: italic;
    // font-weight: bold;
    font-family: "harlow";

    // // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-logo);
}

.sub-logo {
    padding: 10px;

    // font-style: italic;
    // font-weight: bold;
    font-family: "harlow";

    // // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-mlg);
}

.sub-logo-padding-left {
    padding-left: 100px !important;
}

.app-logo {
    padding: 10px;

    // font-style: italic;
    // font-weight: bold;
    font-family: "ardarling";

    // // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-mlg);
}

.fab-text {
    font-weight: bold;
    text-transform: uppercase;
    font-size: var(--font-size-s);
}

.paste-code {
    font-style: normal;
    font-weight: bold;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    white-space: normal;
    font-size: var(--font-size-xxl);
}

.heading {
    font-style: normal;
    font-weight: bold;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    white-space: normal;
    font-size: var(--font-size-lg);
}

.details {
    font-style: normal;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-m);

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-lg {
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-lg);

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-xl {
    font-weight: normal;

    // font-family: Stencil Std, fantasy;
    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-xl);
    font-style: bold;

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-xxl {
    font-weight: normal;

    // font-family: Stencil Std, fantasy;
    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-xxl);
    font-style: bold;

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-mlg {
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-mlg);

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-m {
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-m);

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-s {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-s);

    // word-wrap: break-word;
    white-space: normal;
}

.label-text-s {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-s) !important;

    // word-wrap: break-word;
    white-space: normal;
}

.label-text-m {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-m) !important;

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-s2 {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-s2);

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-m-imp {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-m) !important;

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-s-imp {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-s) !important;

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-xs {
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-xs);

    // word-wrap: break-word;
    white-space: normal;
}

.label-text-xs {
    // padding: 10px;
    // font-style: italic;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-xs) !important;

    // word-wrap: break-word;
    white-space: normal;
}

.info-text-xxs {
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-xxs);

    // word-wrap: break-word;
    white-space: normal;
}

.input-number-style {
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-xxl);

    text-align: center;

    // word-wrap: break-word;
    white-space: normal;
}

.button-font-size-xs {
    font-size: var(--button-font-size-xs);
    white-space: break-spaces;
    font-weight: bold;
}

.button-font-size-s {
    font-size: var(--button-font-size-s);
    white-space: break-spaces;
    font-weight: bold;
}

.button-font-size-m {
    font-size: var(--button-font-size-m);
    white-space: break-spaces;
    font-weight: bold;
}

.title-m {
    font-size: var(--font-size-title-m);
}

.title-s {
    font-size: var(--font-size-title-s);
}

.title-xs {
    font-size: var(--font-size-title-xs);
}

.heading-item {
    font-style: normal;
    font-weight: bold;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-heading-item);

    // word-wrap: break-word;
    white-space: normal;
}

.counter {
    font-style: bold;
    font-weight: normal;

    // font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: var(--font-size-counter);
    white-space: normal;
}

.pie-label {
    font-size: var(--font-size-m) !important;
}

.leplace-menu-text {
    font-size: var(--font-size-xs);
}

.ion-input-center {
}

.ion-input-center .text-input {
    text-align: center;
}

.hud-input {
    border-style: none;
    width: 100%;
}

.text-div-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
}

.text-color-overlay {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;

    //border-radius: 12px;
    border-radius: var(--border-radius-inner-overlay);
    border-style: solid;
    border-color: var(--lp-border-color-item);
    border-width: 2px;
}

.text-color-overlay-s {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;

    // border-radius: 12px;
    border-radius: var(--border-radius-inner-global);
    border-style: solid;
    border-color: var(--lp-border-color-item);
}

.text-color-overlay-flush {
    // border-radius: 12px;
    border-radius: var(--border-radius-inner-global);
    border-style: solid;
    border-color: var(--lp-border-color-item);
}

.toolbar-title-md,
.toolbar-title-ios {
    font-weight: bold;

    // font-size: 18px;
    font-size: var(--font-size-s);
}

.title-ios {
    height: 60px !important;
}

.menu-title-ios-adapter {
    border-top-right-radius: var(--border-radius-inner-overlay);

    .title-ios {
        padding-left: 25px;
    }
}

.toolbar-title-ios {
    text-align: left;
}

.toolbar-title-center {
    .toolbar-title-ios {
        text-align: center;
    }
}

.toolbar-title-left {
    .toolbar-title-ios {
        text-align: left;
    }
}

.text-input-label {
    cursor: default;
    opacity: 0.3;
    pointer-events: none;
}

.text-transform-none {
    text-transform: none !important;
}

.text-strikethrough {
    text-decoration: line-through;
}

.strikediag {
    background: linear-gradient(
        to left top,
        transparent 47.75%,
        currentColor 49.5%,
        currentColor 50.5%,
        transparent 52.25%
    );
}
