.flex-card {
    // background-image:url("https://cdn.pixabay.com/photo/2018/01/25/14/15/turkey-3106216__340.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    vertical-align: middle;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    // width: 100%;
    // border-width: 5px;
    // border-width: var(--lp-border-width);
    border-width: 0px;
    border-style: solid;

    // padding-top: 10px;
    // margin-left: 10px;
    // margin-right: 5px;
    // margin-bottom: 2px;
    // margin-top: 2px;
    border-radius: var(--border-radius-global);

    // border-radius: 10px;
    position: relative;
    cursor: pointer;

    // text-shadow:
    // -1px -1px 0 #000,
    // 1px -1px 0 #000,
    // -1px 1px 0 #000,
    // 1px 1px 0 #000;
}

// .flex-card ion-col {
//     padding: 0px;
// }

.flex-card-grid-no-padding ion-col {
    padding: 0px;
}

.editor-location-card {
    height: 180px !important;
}

.story-card {
    height: 220px !important;
}

.story-card-small {
    height: 140px !important;
}

.story-card-large {
    height: 220px !important;
}

.story-card-locked {
    opacity: 0.5;
}

.item-card {
    height: 140px !important;
}

.item-card-small {
    height: 70px !important;
}

.category-card {
    height: 120px !important;
}

.place-card {
    min-height: 110px !important;

    .content {
        height: 98px !important;
        min-height: 98px !important;
    }
}

.leader-card {
    height: 140px !important;

    .padding-title {
        padding-bottom: 16px !important;
    }
}

.leader-card-v2 {
    height: 160px !important;
}

.leader-card-selected-v2 {
    // height: 160px !important;
    height: 192px !important;
}

.bplace-card {
    // height: 160px !important;
}

.group-role-card {
    // height: 180px !important;
}

.mp-leader-card {
    height: 100px !important;

    .padding-title {
        // padding-bottom: 16px !important;
    }
}

.mp-user-card {
    height: 140px !important;

    .padding-title {
        padding-bottom: 8px !important;
    }
}

.group-card {
    height: 140px !important;
}

.place-ads-card {
    height: 180px !important;
}

.card-shape {
    border-radius: var(--border-radius-inner-global);
}

.card-selected {
    border-width: var(--lp-border-width);
    border-style: solid;
    border-color: #{rgba($lp-color-primary-text, 0.5) !important};
    border-radius: var(--border-radius-inner-global);
    padding-top: 5px;
    padding-bottom: 5px;
}

.pac-card {
    // margin: 10px 10px 0 0;
    // border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;

    // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    // background-color: #fff;
    font-family: Roboto;
    font-size: 18px;
    margin-top: 36px;
    padding-left: var(--xsmall);
    padding-right: var(--xsmall);
    background: rgba(0, 0, 0, 0);
}

.selected-card {
    border-color: white;
    border-width: 2px;
    border-radius: var(--border-radius-global);
    border-style: solid;
}

.wrap-img-container {
    margin-top: 6px;
    position: relative;
    display: inline-block;
}

.wrap-img-button {
    background-image: url("../../assets/icons/zoom.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // background-color: rgba(0, 0, 0, 0);
    background-color: #fcd182cc;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    right: 9px;
    width: 36px;
    height: 36px;
    z-index: 1;
}

.ngb-slider-img-padding {
    padding-bottom: 80px;
}

.ngb-slider-img-padding-large {
    padding-bottom: 120px;
}
