.ion-searchbar-custom {
    --clear-button-color: initial;
    --cancel-button-color: var(--lp-color-primary-text);
    --color: var(--lp-color-primary-text);
    --icon-color: var(--lp-color-primary-icon);
    --background: var(--ion-background-color, #fff);

    .searchbar-input.sc-ion-searchbar-md {
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-padding-start: 45px !important;
        padding-inline-start: 45px !important;
    }

    .searchbar-cancel-button.sc-ion-searchbar-md {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

.ion-searchbar-custom-alt {
    --clear-button-color: initial;
    --cancel-button-color: var(--lp-color-alternate-text);
    --color: var(--lp-color-alternate-text);
    --icon-color: var(--lp-color-alternate-text);
    --background: var(--lp-color-alternate);
    --border-radius: var(--border-radius-global);

    .searchbar-input.sc-ion-searchbar-md {
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-padding-start: 45px !important;
        padding-inline-start: 45px !important;
        font-size: var(--font-size-m);
    }

    .searchbar-cancel-button.sc-ion-searchbar-md {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

.ion-calendar-custom {
    // background-color: rgba(0, 0, 0, 0) !important;

    // .title .back .forward .switch-btn {
    //     color: var(--lp-color-primary-text) !important;
    // }

    // .button-native {
    //     color: var(--lp-color-primary-text) !important;
    // }

    // p {
    //     color: var(--lp-color-primary-text) !important;
    // }
}

.rating-view {
    color: var(--lp-color-alternate);
}

.rating-input {
    color: var(--lp-color-alternate);
}
