.fab-margin-top-large {
    margin-top: 80px;
}

.fab-no-margin {
    margin: 0px;
}

.fab-margin-top-gmap-detail {
    margin-top: 80px;
    left: 0 !important;
}

.fab-margin-top-s {
    margin-top: 10px;
}

.fab-margin-bottom-gmap {
    margin-bottom: 140px;
}

.card-padding-s {
    padding: 2px !important;
}

.gmap-progress-height {
    height: 40px;
}

.mat-row-format {
    min-height: 24px !important;
}

.mat-header-row-format {
    .mat-header-cell {
        font-size: var(--font-size-s) !important;
        color: var(--lp-color-primary-text) !important;
    }
}

.datetime-button-style {
    &::part(native) {
        background: var(--lp-color-bg-dark);
    }
}

.datetime-modal-style {
    border-radius: var(--border-radius-global);
}
