
.leplace-icon {
    font-size: 20px;
}

.leplace-icon-m {
    font-size: 20px;
}

.leplace-icon-s {
    font-size: 12px;
}

.leplace-icon-fixed {
    width: 24px;
    height: 24px;
    ion-icon {
        width: 24px;
        height: 24px;
    }
    margin: auto;
}

.leplace-icon-fixed-large {
    width: 48px;
    height: 48px;
    ion-icon {
        width: 48px;
        height: 48px;
    }
    margin: auto;
}

.leplace-icon-fixed-s {
    width: 12px;
    height: 12px;
    ion-icon {
        width: 12px;
        height: 12px;
    }
    margin: auto;
}


.leplace-menu-icon {
    font-size: 18px;

    background: none;
    border: none;
    margin: 5px;
    padding: 5px;
    // font-weight: bold;

}

.leplace-icon-div-m {
    height: 24px;
    ion-icon {
        width: 32px;
        height: 32px;
        margin-top: -6px;
    }
}

.toolbar-icon {
    font-size: 18px;
}

.icon-button-round {
    font-size: 24px;
    border-radius: var(--border-radius-inner-button);
}