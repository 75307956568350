:root {
    --action-button-height: 44px;
    --action-button-height-large: 60px;
}

.filters {
    ion-col {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.6rem;

        ion-icon {
            color: #ccc;
        }

        &.col-with-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    p {
        color: #fff;
        margin: 0;
        font-size: 1.6rem;
        line-height: 1.6rem;
    }

    .selected {
        font-weight: 700;
    }
}

.slider-controls {
    ion-col {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.6rem;

        .control-icon {
            color: #ccc;
        }

        &.col-with-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.action-button-fab {
    border-radius: var(--border-radius-inner-button);
    height: 3em;
    min-height: 3em;
    width: auto;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button {
    height: var(--action-button-height);

    // min-height: 4em;
    width: 10em;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.menu-button {
    height: 48px;
}

.options-menu-button {
    .span-icon {
        width: 100%;
        margin-left: -24px;
    }

    .icon-wrapper {
        margin-right: auto;
    }
}

.icon-wrapper-abs {
    position: absolute;
}

.action-button-tabs {
    // height: 40px;
    // height: 18px;
    height: 24px;

    // min-height: 4em;
    width: 80px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);
    font-size: 10px;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-fill-xs {
    height: 28px;

    // min-height: 30px;
    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-fill-space-s {
    height: 42px;

    // min-height: 30px;
    // width: 99.5%;
    width: calc(100% - 10px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-fill-space-m {
    height: 42px;

    // min-height: 30px;
    // width: 99.5%;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-tabs-icon {
    height: 40px;
    width: 60px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);
    font-size: 10px;
}

.action-button-xs {
    height: var(--action-button-height);
    width: 42px;
    height: 42px;
    border-radius: 50%;

    // border-style: solid !important;
    // border-width: var(--border-outset-button) !important;
}

.action-button-fit {
    height: var(--action-button-height);
    height: 42px;
    border-radius: 50%;
    // border-style: solid !important;
    // border-width: var(--border-outset-button) !important;
}

.action-button-paging {
    height: var(--action-button-height);
    width: 48px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);

    // border-style: solid !important;
    // border-width: var(--border-outset-button) !important;
}

.action-button-icon-only {
    margin: 0px;
    padding: 0px;
    height: 24px;

    // width: 24px;
    width: 100%;
}

.action-button-expand {
    height: 25px;
    width: 25px;
    font-size: 8px !important;
    font-weight: bold;
    border-radius: 50%;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-expand-wide {
    height: 25px;
    width: 100%;
    font-size: 8px !important;
    font-weight: bold;
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-wide {
    height: var(--action-button-height);
    width: 16em;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-fill {
    height: var(--action-button-height);

    // min-height: 30px;
    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-menu {
    height: var(--action-button-height);

    // min-height: 30px;
    // width: 99.5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: 1px !important;
}

.button-rounded {
    border-radius: var(--border-radius-global) !important;
}

.button-border {
    border-style: solid !important;
    border-width: var(--lp-border-width) !important;
}

.action-button-fill-large {
    height: var(--action-button-height-large);

    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-h {
    height: var(--action-button-height);
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.google-button-container {
    margin: 4px 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.round-fab {
    // border-radius: 10px !important;
    // opacity: 0.9;
    border-color: var(--lp-border-color-item);

    // border-radius: var(--border-radius-global) !important;
    border-radius: 100% !important;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
    --border-color: var(--lp-border-color-item);

    // border-radius: var(--border-radius-global) !important;
    --border-radius: 100% !important;
    --border-style: solid !important;
    --border-width: var(--border-outset-button) !important;
}

.round-fab-size {
    margin: 8px;
    width: 50px !important;
    height: 50px !important;
}

.as-text {
    background: none;
    border: none;
    margin: 5px;
    padding: 5px;
    height: 40px;
    font-weight: bold;
    --background: rgba(0, 0, 0, 0);
    --border-width: 0px;
    --border-style: none;
    --padding-start: 5px;
    --padding-end: 5px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --box-shadow: none;
}

.as-text-regular {
    background: none;
    border: none;
    margin: 5px;
    padding: 5px;
    height: 40px;
    --background: rgba(0, 0, 0, 0);
    --border-width: 0px;
    --border-style: none;
    --padding-start: 5px;
    --padding-end: 5px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --box-shadow: none;
}

.menu-button-size {
    min-height: 48px;
    min-width: 48px;
    width: 48px;
}

.menu-button-size-right {
    // width: 54px;
}
