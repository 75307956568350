.no-scroll {
    overflow-y: hidden !important;
}

.scroll-content-no-scroll {
    .scroll-content {
        overflow-y: hidden !important;
    }
}

.scroll-content {
    overflow-y: auto !important;
}

.scrollbar-y-hidden {
    overflow-y: hidden !important;
    overflow-x: hidden;
}

.ion-content-overflow {
    overflow: auto;
    --overflow: auto;
}

.scrollbar-y {
    overflow-y: auto !important;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    // display: none;
}

.infinite-scroll-spacer {
    min-height: 100px;
}

// used to fill height with bottom space for buttons
.scroll-height-btn-2 {
    height: calc(100vh - 100px);
}

// used to fill height with bottom space for buttons
.scroll-height-btn-1 {
    height: calc(100vh - 100px);
}

.scroll-height-btn-1-border {
    height: calc(100vh - 240px);
}

// used for modals that are not full screen
.scroll-height-btn-1-console-border {
    height: calc(80vh - 150px);
}

.scroll-type-2 {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 50px !important;
    bottom: 40px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;

    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-3 {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 65px !important;
    bottom: 55px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px !important;
    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-frame-list {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 0px !important;
    bottom: 60px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
}

.scroll-content-modal-1 {
    position: absolute !important;
    top: 0px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 86px !important;
}

.scroll-content-modal-1-border {
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 82px !important;
}

.scroll-content-modal-1-flush {
    position: absolute !important;
    top: 0px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 0px !important;
}

.scroll-content-modal-1-border-flush {
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 76px !important;
}

.scroll-content-modal-2 {
    position: absolute !important;
    top: 10px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 105px !important;
}

.scroll-content-modal-2s {
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 85px !important;
}

.scroll-content-modal-2-border {
    position: absolute !important;
    top: 10px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 120px !important;
}

.scroll-content-modal-3s {
    position: absolute !important;
    top: 40px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 48px !important;
}

.scroll-content-modal-map {
    position: absolute !important;
    top: 320px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 48px !important;
}

.scroll-content-modal-3 {
    position: absolute !important;
    top: 60px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 48px !important;
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .li {
        flex: 0 0 auto;
    }
}

::-webkit-scrollbar {
    // width: 12px;
    -webkit-overflow-scrolling: touch;
    display: none;
    // height: 5px;
}

.custom-scrollbar {
    scrollbar-gutter: stable both-edges;
}

.custom-scrollbar::-webkit-scrollbar {
    /* width */
    width: 8px;
    display: block;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    /* Track */
    background-color: var(--lp-color-primary-item);
    border-radius: 10px;
    // border-radius: 100px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    /* Handle */
    background-color: var(--lp-color-primary-text);
    border-radius: 10px;
    // border-radius: 100px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    /* Handle on hover */
    background-color: var(--lp-color-primary-text-high);
    border-radius: 10px;
}

.scroll-auto {
    overflow: auto;
    --overflow: auto;
}
