.progress-bar-bg {
    background-color: var(--lp-color-primary);
    width: auto;
    height: 80%;
    border-radius: 5px;
}

.progress-bar {
    background-color: var(--lp-color-light);
    height: 100%;
    border-radius: 5px;
}

.progress-size {
    width: 100px;
    height: 20px;
}

.progress-label {
    font-size: 2.7vw;
    position: absolute;
    text-align: center;
    top: 0.12vw;
    left: 50%;
    transform: translate(-50%, 0);
}

.progress-bar-alternate {
    .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-alternate) !important;

        // background-color: var(--lp-color-primary-item) !important;
    }
}

.progress-bar-alternate-light {
    .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate-70) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate-70) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-alternate-70) !important;

        // background-color: var(--lp-color-primary-item) !important;
    }


    .mat-progress-bar-background {
        fill: rgba(0, 0, 0, 0);
    }

    .mat-progress-bar-buffer {
        background-color: rgba(0, 0, 0, 0.2);
    }   

    // mat-progress-bar-overlay
}

.progress-bar-accent {
    .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-accent) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-accent) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-accent) !important;

        // background-color: var(--lp-color-primary-item) !important;
    }
}

.progress-bar-primary {
    .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-primary-item) !important;

        // background-color: var(--lp-color-action-4) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-primary-item) !important;

        // background-color: var(--lp-color-action-4) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-primary-item) !important;

        // background-color: var(--lp-color-action-4) !important;
    }
}
