.fab-display-top {
    padding-left: var(--lp-item-padding);
    padding-right: var(--lp-item-padding);
    padding-bottom: 4px;
    padding-top: 4px;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.hud {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    //width: 180px;
    width: 60%;
    // width: 80%;
    // width: 100%;

    height: 72px;
    // height: auto;
    // padding: 0px;
    padding-left: 4px;
    padding-right: 4px;

    // text-align: center;
    border-radius: 0 0 32px 32px;
    background-clip: padding-box;
}

.hud-btm {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    //width: 180px;
    width: 50%;
    // height: 50px;
    height: auto;
    padding: 0px;

    // text-align: center;
    border-radius: var(--border-radius-global) (var(--border-radius-global) 0 0);
    background-clip: padding-box;
}

.hud-xp {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    //width: 180px;
    //width: 120px;
    width: 70%;
    height: 32px;
    padding: 5px;

    // border-radius: var(--border-radius-global);
    border-radius: var(--border-radius-global) var(--border-radius-global) 0 0;

    background-clip: padding-box;
}

.hud-button {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    //width: 180px;
    //width: 120px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    // text-align: center;
    background-clip: padding-box;
}

.hud-button-margin {
    margin-bottom: 32px;
    padding-top: 17px;
}

.hud-joystick {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: calc(50% - 80px);
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    width: 160px;
    height: 160px;
    padding: 0px;
    // text-align: center;
    background-clip: padding-box;
}

.hud-left-m {
    position: absolute;
    z-index: 999;
    top: 0;
    // bottom: 0;
    bottom: 70px;
    left: -25px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 40px;
    height: 50vh;
    padding: 0px;

    // text-align: center;
    border-radius: 0 var(--border-radius-global) var(--border-radius-global) 0;
    background-clip: padding-box;
}

.hud-right-m {
    position: absolute;
    z-index: 999;
    top: 0;
    // bottom: 0;
    bottom: 70px;
    right: -25px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 40px;
    height: 50vh;
    padding: 0px;

    // text-align: center;
    border-radius: var(--border-radius-global) 0 0 var(--border-radius-global);
    background-clip: padding-box;
}

.hud-left-icon {
    background-color: var(--lp-color-primary-70) !important;
    border-radius: 0 var(--border-radius-global) var(--border-radius-global) 0;
    padding-top: 14px;
    position: absolute;
    margin-left: 15px;
    width: 50px !important;
    height: 50px !important;
}

.hud-right-icon {
    background-color: var(--lp-color-primary-70) !important;
    border-radius: var(--border-radius-global) 0 0 var(--border-radius-global);
    padding-top: 14px;
    position: absolute;
    margin-right: 15px;
    // right: -20px;
    right: 0px;
    width: 50px !important;
    height: 50px !important;
}

.ngx-charts-heatmap-right {
    position: absolute;
    right: 0px;
}
