.action-button-icon-only {
    --background: rgba(0, 0, 0, 0);
    --color: var(--lp-color-primary-text) !important;
    background: rgba(0, 0, 0, 0);

    .text-color {
        color: var(--lp-color-primary-text) !important;
    }

    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-text) !important;
    }
}

// button@primary
.button-color-primary {
    background-color: var(--lp-color-primary) !important;
    color: var(--lp-color-primary-text);
    // border-color: var(--lp-color-primary-low);
    border-color: var(--lp-color-primary);
    --background: var(--lp-color-primary) !important;
    --color: var(--lp-color-primary-text);
    // --border-color: var(--lp-color-primary-low);
    --border-color: var(--lp-color-primary);
  
    .text-color {
        color: var(--lp-color-primary-text) !important;
    }
  
    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-icon) !important;
    }
  }
  
  .button-color-primary-grad {
    background: #{radial-gradient(rgba($lp-color-primary, var(--button-grad-1)), rgba($lp-color-primary, var(--button-grad-1)), rgba($lp-color-primary, var(--button-grad-2)))};
    border-color: var(--lp-border-color-item);
    color: var(--lp-color-primary-text);
    --background: #{radial-gradient(rgba($lp-color-primary, var(--button-grad-1)), rgba($lp-color-primary, var(--button-grad-1)), rgba($lp-color-primary, var(--button-grad-2)))};
    --border-color: var(--lp-border-color-item);
    --color: var(--lp-color-primary-text);
  
    .text-color {
        color: var(--lp-color-primary-text) !important;
    }
  
    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-text) !important;
    }
  }

  .button-color-primary-70 {
    // background-color: var(--lp-color-primary) !important;
    border-color: var(--lp-border-color-item);
    color: var(--lp-color-primary-text);
    --background: var(--lp-color-primary-70) !important;
    --color: var(--lp-color-primary-text);
    --border-color: var(--lp-border-color-item);

    .text-color {
        color: var(--lp-color-primary-text) !important;
    }

    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-text) !important;
    }
}
  
  // button@alternate
  .button-color-alternate {
    background-color: var(--lp-color-alternate) !important;
    color: var(--lp-color-alternate-text);
    border-color: var(--lp-color-alternate);
    --background: var(--lp-color-alternate) !important;
    --color: var(--lp-color-alternate-text);
    --border-color: var(--lp-color-alternate);
  
    .text-color {
        color: var(--lp-color-alternate-text) !important;
    }
  
    .icon-md,
    .icon-ios {
        color: var(--lp-color-alternate-icon) !important;
    }
  }
  
  .button-color-alternate-grad {
    background: #{radial-gradient(rgba($lp-color-alternate, var(--button-grad-1)), rgba($lp-color-alternate, var(--button-grad-1)), rgba($lp-color-alternate, var(--button-grad-2)))};
    border-color: var(--lp-color-alternate);
    color: var(--lp-color-alternate-text);
    --background: #{radial-gradient(rgba($lp-color-alternate, var(--button-grad-1)), rgba($lp-color-alternate, var(--button-grad-1)), rgba($lp-color-alternate, var(--button-grad-2)))};
    --border-color: var(--lp-color-alternate);
    --color: var(--lp-color-alternate-text);
  
    .text-color {
        color: var(--lp-color-alternate-text) !important;
    }
  
    .icon-md,
    .icon-ios {
        color: var(--lp-color-alternate-text) !important;
    }
  }
  
  
  // button@accent
  .button-color-accent {
    background-color: var(--lp-color-accent) !important;
    color: var(--lp-color-accent-text);
    border-color: var(--lp-color-accent);
    --background: var(--lp-color-accent) !important;
    --color: var(--lp-color-accent-text);
    --border-color: var(--lp-color-accent);
  
    .text-color {
        color: var(--lp-color-accent-text) !important;
    }
  
    .icon-md,
    .icon-ios {
        color: var(--lp-color-accent-icon) !important;
    }
  }
  
  // button@warn
  .button-color-warn {
    background-color: var(--lp-color-warn) !important;
    color: var(--lp-color-warn-text);
    border-color: var(--lp-color-warn);
    --background: var(--lp-color-warn) !important;
    --color: var(--lp-color-warn-text);
    --border-color: var(--lp-color-warn);
  
    .text-color {
        color: var(--lp-color-warn-text) !important;
    }
  
    .icon-md,
    .icon-ios {
        color: var(--lp-color-warn-icon) !important;
    }
  }
  
  
  // other
  
  .button-color-custom-facebook {
      background-color: var(--lp-color-facebook) !important;
      border-color: var(--lp-color-facebook) !important;
      color: var(--lp-color-primary-text);
      --background: var(--lp-color-facebook) !important;
      --border-color: var(--lp-color-facebook) !important;
      --color: var(--lp-color-primary-text);
  
      .text-color {
          color: var(--lp-color-primary-text) !important;
      }
  
      .icon-md,
      .icon-ios {
          color: var(--lp-color-primary-text) !important;
      }
  }
  
  .button-color-custom-google {
      background-color: var(--lp-color-google) !important;
      border-color: var(--lp-color-google) !important;
      color: var(--lp-color-primary-text);
      --background: var(--lp-color-google) !important;
      --border-color: var(--lp-color-google) !important;
      --color: var(--lp-color-primary-text);
  
      .text-color {
          color: var(--lp-color-primary-text) !important;
      }
  
      .icon-md,
      .icon-ios {
          color: var(--lp-color-primary-text) !important;
      }
  }
  
  .button-color-custom-apple {
      background-color: var(--lp-color-apple) !important;
      border-color: var(--lp-color-apple) !important;
      color: var(--lp-color-text-black);
      --background: var(--lp-color-apple) !important;
      --border-color: var(--lp-color-apple) !important;
      --color: var(--lp-color-text-black);
  
      .text-color {
          color: var(--lp-color-primary-text) !important;
      }
  
      .icon-md,
      .icon-ios {
          color: var(--lp-color-primary-text) !important;
      }
  }
  
  .button-color-transparent {
      --background: rgba(0, 0, 0, 0);
  }
  
  .button-color-menu {
      background: var(--lp-color-primary-70) !important;
      border-color: var(--lp-color-primary-dark);
      color: var(--lp-color-primary-text-low);
      --background: rgba(0, 0, 0, 0) !important;
      --border-color: var(--lp-color-primary-text-low);
      --color: var(--lp-color-primary-text-low);
  
      .text-color {
          color: var(--lp-color-primary-text-low) !important;
      }
  
      .icon-md,
      .icon-ios {
          color: var(--lp-color-primary-text-low) !important;
      }
  }

  .action-button-paging {
    --background: rgba(0, 0, 0, 0);
    --color: var(--lp-color-primary-text-low) !important;
    background: rgba(0, 0, 0, 0);

    .text-color {
        color: var(--lp-color-primary-text-low) !important;
    }

    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-text-low) !important;
    }
}