:root {
    --bg-animation: 0.7s;
}

.img-bg-login {
    // background-image: linear-gradient(to top, #0c3483 0%, #a2b6df 100%, #6b8cce 100%, #a2b6df 100%);
    // background-image: url("../../assets/img/paris.jpg");
    background: url("../../assets/img/photos/bench.jpg") center center no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #{rgba($lp-color-bg, 0.7)};
}

.bg-container-fill {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-global);
}

.bg-container-fill-margin-top {
    width: 100%;
    height: calc(100% - var(--leplace-status-bar-height));
}

// .bg-container-h {
//     width: 100%;
//     height: 80px;
// }

// .bg-container-item {
//     width: 100%;
// }

// .bg-container-item .content ion-grid {
//     padding: 10px;
// }

.bg-container {
    // height: 100px;
    // height: 300px;
    //border: 1px solid #000;
    position: relative;

    // position: fixed;
    // -webkit-mask-image: url("../../assets/img/photos/grain.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-image-contain {
    background-size: contain !important;
}

.bg-container-fade {
    background-image: url("../../assets/img/photos/placeholder.jpg");
    -webkit-transition: background var(--bg-animation) linear;
    -moz-transition: background var(--bg-animation) linear;
    -ms-transition: background var(--bg-animation) linear;
    -o-transition: background var(--bg-animation) linear;
    transition: background var(--bg-animation) linear;
}

.bg-fadein {
    // -webkit-animation: fadein var(--bg-animation);
    // -moz-animation: fadein var(--bg-animation);
    // -ms-animation: fadein var(--bg-animation);
    // -o-animation: fadein var(--bg-animation);
    // animation: fadein var(--bg-animation);
}

.bg-hidden {
    // opacity: 0;
}

.bg-container .content {
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.bg-container::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;

    // background-position: 50% 50%;
    // object-fit: cover;

    // -webkit-transition: background var(--bg-animation);
    // -moz-transition: background var(--bg-animation);
    // -ms-transition: background var(--bg-animation);
    // -o-transition: background var(--bg-animation);
    //   transition: background var(--bg-animation);
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
