.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.icon-text-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
}
