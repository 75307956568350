.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.underline {
    text-decoration: underline;
}

.center-text {
    text-align: center;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.bold {
    font-weight: bold !important;
}

.italic {
    font-style: italic !important;
}

.text-div-margin {
    padding: 4px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.no-underline {
    text-decoration: none;
}
