// text@primary
.text-color-primary {
  color: var(--lp-color-primary-text) !important;
}

.text-color-primary-high {
  color: var(--lp-color-primary-text-high) !important;
}

.text-color-primary-low {
  color: var(--lp-color-primary-text-low) !important;
}

// text@alternate
.text-color-alternate {
  color: var(--lp-color-alternate-text) !important;
}

.text-color-alternate-high {
  color: var(--lp-color-alternate-text-high) !important;
}

.text-color-alternate-low {
  color: var(--lp-color-alternate-text-low) !important;
}

// text@accent
.text-color-accent {
  color: var(--lp-color-accent-text) !important;
}

.text-color-accent-high {
  color: var(--lp-color-accent-text-high) !important;
}

.text-color-accent-low {
  color: var(--lp-color-accent-text-low) !important;
}

// text@warn
.text-color-warn {
  color: var(--lp-color-warn-text) !important;
}

.text-color-warn-contrast {
  color: var(--lp-color-warn) !important;
}

.text-color-warn-high {
  color: var(--lp-color-warn-text-high) !important;
}

.text-color-warn-low {
  color: var(--lp-color-warn-text-low) !important;
}

// other

.text-color-primary-chat {
  color: var(--lp-color-primary-text-chat) !important;
}
