.account-chart {
    .ngx-charts .gridline-path {
        stroke-width: 0.1 !important;
    }

    .polar-chart .radial-gridline-path {
        stroke-dasharray: 0 0 !important;

        // stroke-width: 0;
    }
}

.sound-chart {
    .ngx-charts .gridline-path {
        stroke-width: 1 !important;
    }

    .polar-chart .radial-gridline-path {
        stroke-dasharray: 0 0 !important;

        // stroke-width: 0;
    }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--lp-color-primary-text) !important;
}

.label-md,
.label-ios {
    margin: 8px 0px 8px 0;
}

// list

.list-md,
.list-ios {
    margin: -2px 0 4px;
}

.list-md-noborder,
.list-ios-noborder {
    margin: -2px 0 4px;
}

.list-md-noborder .item-block .item-inner,
.list-ios-noborder .item-block .item-inner {
    border-bottom: 0px solid white;
}

.list-md .item-block .item-inner,
.list-ios .item-block .item-inner {
    // border-color: var(--lp-color-primary-item);
    border-bottom: 0px solid var(--lp-color-primary-item);
}

button.item-ios:not([detail-none]) .item-inner {
    background-image: none !important;
}

.list-ios > .item-block:first-child {
    border-top: 0px solid var(--lp-color-primary-item) !important;
}

.list-ios > .item-block:last-child,
.list-ios > .item-wrapper:last-child .item-block {
    border-bottom: 0px solid var(--lp-color-primary-item) !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: rgba(0, 0, 0, 0) !important;
}

.col {
    padding-top: 0px;
    padding-bottom: 0px;
    position: initial !important;
}

ion-col {
    position: initial !important;
}

.item-divider-md,
.item-divider-ios {
    border-width: 4px;
}

.item-md,
.item-ios {
    padding-left: 10px;

    // padding-right: 0;
    // position: relative;
    // font-size: 1.6rem;
    // font-weight: normal;
    // text-transform: none;
    // color: #000;
    // background-color: #fff;
    // -webkit-box-shadow: none;
    // box-shadow: none;
    // -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
    // transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-slide-width {
    width: 100px !important;
}

.mat-progress-bar {
    height: 8px !important;

    // border-radius: 4px !important;
}

.leplace-progress-bar-s {
    .mat-progress-bar {
        height: 4px !important;

        // border-radius: 4px !important;
    }
}

.leplace-progress-bar-large {
    .mat-progress-bar {
        height: var(--leplace-progress-bar-height) !important;
    }
}

.leplace-progress-bar-large-ios {
    .mat-progress-bar {
        height: var(--leplace-progress-bar-height-ios) !important;
    }
}

.progress-rounded {
    border-radius: 4px !important;

    .mat-progress-bar-buffer {
        border-radius: 4px !important;
    }

    .mat-progress-bar-fill::after {
        border-radius: 4px !important;
    }

    //   .mat-progress-bar-element {
    //     border-radius: 4px !important;
    //   }
}

.progress-no-animation {
    animation: none !important;
    transition: none !important;

    .mat-progress-bar {
        animation: none !important;
        transition: none !important;
    }

    .mat-progress-bar-fill {
        animation: none !important;
        transition: none !important;
    }

    .mat-progress-bar-buffer {
        animation: none !important;
        transition: none !important;
    }
}

.progress-spinner-left {
}

.progress-spinner-center {
}

.progress-spinner-center .mat-progress-spinner {
    margin: 0 auto;
    height: 40px !important;
    width: 40px !important;
}

.progress-spinner-center .mat-progress-spinner svg {
    height: 40px !important;
    width: 40px !important;
}

.progress-spinner-left .mat-progress-spinner {
    margin: 0 0;
    height: 40px !important;
    width: 40px !important;
}

.progress-spinner-left .mat-progress-spinner svg {
    height: 40px !important;
    width: 40px !important;
}

.header-md::after,
.header-ios::after {
    background-image: none;

    // border-bottom: 4px solid var(--lp-color-action-3);
    //     border-bottom: 4px solid var(--lp-color-primary-item);
    //    bottom: -4px;
}

ion-content .fixed-content,
.scroll-content {
    margin-top: 42px !important;
}

.toolbar-title-md,
.toolbar-title-ios {
    color: var(--lp-color-primary-text);
}

.bar-button-default-md,
.bar-button-clear-md-default,
.bar-button-md-default,
.bar-button-default-ios,
.bar-button-clear-ios-default,
.bar-button-ios-default {
    color: var(--lp-color-primary-text);
}

.bar-button-md,
.bar-button-ios {
    font-size: 12px;
}

.button-md-primary,
.button-ios-primary {
    color: var(--lp-color-primary-text);
}

// .button-md .button-effect {
//     color: var(--lp-color-primary-text);
// }

.button-md,
.button-ios {
    font-size: 16px;

    .button-effect {
        color: var(--lp-color-primary-text);
    }
}

.button-ios {
    text-transform: uppercase;
    font-weight: bold;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="number"] {
    font-family: monospace !important;
}


