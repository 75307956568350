.description-frame {
    p {
        margin-bottom: 3px;
        margin-top: 3px;
        min-height: 0.7em;
        // color: var(--lp-color-light);
    }

    a {
        color: var(--lp-color-primary-text) !important;
    }
}

.description-frame-p {
    p {
        min-height: 0.7em;
        color: var(--lp-color-primary-text) !important;
    }
}

.rating-view {
    font-size: 30px;
}

.rating-input {
    font-size: 40px;
}

.leplace-textarea {
}

.leplace-textarea .text-input {
    //background: var(--lp-color-primary-text);
    //border-style: inset;
    border-radius: 4px;
    border-width: 1px;
    font-style: italic;
    display: block;
    padding: 5px;

    //border-color: var(--lp-color-primary);
    // color: var(--lp-color-light);
    color: var(--lp-color-light);
}

.leplace-textarea-chat {
}

.leplace-textarea-chat .text-input {
    display: block;

    // padding: 5px;
}
