.slide-image-inner-modal {
    max-height: 30vh;
    border-radius: 20px;
}

.slide-image-inner-modal-provider {
    max-height: 30vh;
    height: 60px;
    border-radius: 20px;
}
