.item-level {
    height: 30px;
    padding-top: 6px;
}

.chat-scroll {
    height: 60px;
    overflow-y: scroll;
}

.chat-scroll-large {
    position: absolute;
    top: 60px;
    left: 0px;
    height: calc(100% - 60px);
    overflow-y: scroll;
}

.padding-chat-send {
    padding-left: 10px;
    padding-right: 50px;
}

.inventory-height {
    // height: 76%;
    height: 95%;
}

.height-auto {
    height: auto !important;
}

.item-slide-height {
    max-height: 160px;
}

.inventory-height-2 {
    // height: 76%;
    height: 70%;
    max-height: 250px;
}

.team-stats-height {
    height: calc(100% - 50px);
}

.story-data-height {
    height: calc(100vh - 300px);
}

.story-data-height-locked {
    height: calc(100vh - 270px);
}

.event-data-height {
    height: calc(100vh - 340px);
}

.gmap-data-height {
    height: calc(100vh - 200px);
}

.full-height {
    // // original:
    // height: 100vh;
    // // safari wants this:
    height: calc(100vh - 80px); // was 50px on android
}

.full-height-modal {
    height: calc(100vh - 100px); // was 50px on android
}

.height-100 {
    height: 100%;
}

.height-40 {
    height: 40vh;
}

.video-portrait-mobile {
    width: 40vh;
    height: 80vh;
}

.video-landscape {
    width: 100%;
    height: 40vh;
}

.height-320px {
    height: 320px;
}

.video-frame-size {
    height: 320px;
    max-height: 320px;
    width: 100%;
}

.half-height {
    height: 50vh;
}

.metrics-height {
    // height: 80%;
    height: calc(100vh - 180px);
}

.gmap-transp {
    background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==') !important;
    background: rgba(0, 0, 0, 0) url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==') !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.generic-chart-container {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.test-chart-container {
    // width: 400px;
    // width: calc(100vw - 20px);
    // height: 400px;
    // height: calc(100vh - 40px);
    // min-height: 100%;
}

.sound-button {
    // max-height: calc(100vw - 100px);
    max-height: calc(100vh - 250px);
}




.cluster-icon {
    width: 42px;
    height: 42px;
    line-height: 40px;
  }

.custom-cluster-icon {
    background-color: rgba(255, 0, 0, 0.6);
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    font-size: 32px;
}
