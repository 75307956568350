// @import "font-awesome";

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$headings-font-weight: 300;
$border-radius-global-s: 12;
$border-radius-global-raw: 22;
$border-radius-global-button-raw: 25;
$border-radius-inner-global-raw: 21;
$border-width-raw: 2;
$border-radius-toolbar: 50;

// #2488c2
// #249ac2
// #188181

$lp-color-primary-card: #197fbb;
$lp-color-primary-dark: #253350;
$lp-color-primary-item: #16587e;
$lp-color-primary-faded: #a3c8d3;
$lp-color-light: #f4f4f4;
$lp-color-gray: #d3d3d3;
$lp-color-button: #369fbe;
$lp-color-action-1: #46b666;
$lp-color-action-2: #e75f4d;
$lp-color-action-3: #ebb24a;
$lp-color-action-3-light: #fcd182;
$lp-color-text-1: #6bdb8b;
$lp-color-text-2: #e75f4d;
$lp-color-text-3: #fcd182;
$lp-color-text-4: #3384b3;
$lp-color-text-black: black;
$lp-color-cool: #20bec6;
$lp-color-cool-2: #8ec4c7;
$lp-color-favorite: #69bb7b;
$lp-color-twitter: #1da1f4;
$lp-color-google: #dc4a38;
$lp-color-apple: white;
$lp-color-vimeo: #23b6ea;
$lp-color-facebook: #3b5998;
$lp-color-aubergine: #1c4b66;
$lp-color-card-bg: #02375a;
$lp-color-card-bg2: #9bbcdb;

// primary
$lp-color-primary: #1e72a3;
$lp-color-primary-high: #ebb24a;
$lp-color-primary-low: #bbd6f0;

$lp-color-primary-text: #f8f8f8;
$lp-color-primary-text-high: #fcd182;
$lp-color-primary-text-low: #bbd6f0;

$lp-color-primary-icon: #f8f8f8;
$lp-color-primary-icon-high: #fcd182;
$lp-color-primary-icon-low: #bbd6f0;

// alternate
$lp-color-alternate: #ebb24a;
$lp-color-alternate-high: #1e72a3;
$lp-color-alternate-low: #bbd6f0;

$lp-color-alternate-text: #253350;
$lp-color-alternate-text-high: #16587e;
$lp-color-alternate-text-low: #bbd6f0;

$lp-color-alternate-icon: #253350;
$lp-color-alternate-icon-high: #16587e;
$lp-color-alternate-icon-low: #bbd6f0;

// accent
$lp-color-accent: #46b666;
$lp-color-accent-high: #253350;
$lp-color-accent-low: #bbd6f0;

$lp-color-accent-text: #253350;
$lp-color-accent-text-high: #fcd182;
$lp-color-accent-text-low: #bbd6f0;

$lp-color-accent-icon: #253350;
$lp-color-accent-icon-high: #fcd182;
$lp-color-accent-icon-low: #bbd6f0;

// warn
$lp-color-warn: #e75f4d;
$lp-color-warn-high: #ebb24a;
$lp-color-warn-low: #bbd6f0;

$lp-color-warn-text: #253350;
$lp-color-warn-text-high: #fcd182;
$lp-color-warn-text-low: #bbd6f0;

$lp-color-warn-icon: #253350;
$lp-color-warn-icon-high: #fcd182;
$lp-color-warn-icon-low: #bbd6f0;

// other
// $lp-color-primary-text-element: #fcd182;
$lp-color-primary-text-element: #f8f8f8;
$lp-color-primary-text-chat: #08142c;
$lp-color-alert-text: #253350;

// $lp-color-primary-text: #fcd182;
// $lp-color-primary-text-element: #fcd182;
// $lp-color-primary-text-chat: #08142c;

$lp-color-primary-alert: #16587e;
$lp-color-bg2: #2a364e;
$lp-color-selected: #2c86a1;

// $lp-color-bg2: #f8f8f8;
$lp-color-white: #f8f8f8;

$lp-color-bg: #1e72a3;
$lp-color-bg-alert: #ebb24a;
$lp-color-bg-dark: #1c4b66;
$lp-color-bg-dark2: #9bbcdb;

// $lp-color-negative-bg: #f8f8f8;
$lp-color-negative-bg: #31769e;
$lp-color-text-overlay-bg: #275874;

// $lp-color-text-overlay: #fcd182;
$lp-color-text-overlay: #f8f8f8;
$lp-color-bgcontainer: #1e6a8d;
$lp-color-filter-1: #1e6a8d;
$lp-color-filter-2: #1e6a8d;
$lp-color-filter-3: #1e72a3;
$lp-color-filter-4: #197fbb;
$lp-color-danger: #fa7f7f;
$lp-color-light-text: #f4f4f4;
$lp-color-list-item-color: #16587e;
$lp-color-primary-text-secure: #885b28;
$lp-color-orange-favorite: rgb(255, 175, 27);

$lp-color-alternate: #ebb24a;
$lp-color-toolbar: #1e72a3;
$lp-color-toolbar-alternate: #ebb24a;
$lp-color-toolbar-warn: #e75f4d;
$lp-color-toolbar-faded: #a3c8d3;

$hud-line-width: 2;
$hud-line-width-inner: 1;

$hud-opacity: 0.8;
$hud-opacity-debug: 0.4;
$hud-bgcolor: $lp-color-light;

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** leplace theme **/

    /** leplace theme **/
    // primary
    --lp-color-primary: #{$lp-color-primary};
    --lp-color-primary-high: #{$lp-color-primary-high};
    --lp-color-primary-low: #{$lp-color-primary-low};
    --lp-color-primary-text: #{$lp-color-primary-text};
    --lp-color-primary-text-high: #{$lp-color-primary-text-high};
    --lp-color-primary-text-low: #{$lp-color-primary-text-low};
    --lp-color-primary-icon: #{$lp-color-primary-icon};
    --lp-color-primary-icon-high: #{$lp-color-primary-icon-high};
    --lp-color-primary-icon-low: #{$lp-color-primary-icon-low};

    // alternate
    --lp-color-alternate: #{$lp-color-alternate};
    --lp-color-alternate-high: #{$lp-color-alternate-high};
    --lp-color-alternate-low: #{$lp-color-alternate-low};
    --lp-color-alternate-text: #{$lp-color-alternate-text};
    --lp-color-alternate-text-high: #{$lp-color-alternate-text-high};
    --lp-color-alternate-text-low: #{$lp-color-alternate-text-low};
    --lp-color-alternate-icon: #{$lp-color-alternate-icon};
    --lp-color-alternate-icon-high: #{$lp-color-alternate-icon-high};
    --lp-color-alternate-icon-low: #{$lp-color-alternate-icon-low};

    // accent
    --lp-color-accent: #{$lp-color-accent};
    --lp-color-accent-high: #{$lp-color-accent-high};
    --lp-color-accent-low: #{$lp-color-accent-low};
    --lp-color-accent-text: #{$lp-color-accent-text};
    --lp-color-accent-text-high: #{$lp-color-accent-text-high};
    --lp-color-accent-text-low: #{$lp-color-accent-text-low};
    --lp-color-accent-icon: #{$lp-color-accent-icon};
    --lp-color-accent-icon-high: #{$lp-color-accent-icon-high};
    --lp-color-accent-icon-low: #{$lp-color-accent-icon-low};

    // warn
    --lp-color-warn: #{$lp-color-warn};
    --lp-color-warn-high: #{$lp-color-warn-high};
    --lp-color-warn-low: #{$lp-color-warn-low};
    --lp-color-warn-text: #{$lp-color-warn-text};
    --lp-color-warn-text-high: #{$lp-color-warn-text-high};
    --lp-color-warn-text-low: #{$lp-color-warn-text-low};
    --lp-color-warn-icon: #{$lp-color-warn-icon};
    --lp-color-warn-icon-high: #{$lp-color-warn-icon-high};
    --lp-color-warn-icon-low: #{$lp-color-warn-icon-low};

    // other
    --lp-color-primary-card: #{$lp-color-primary-card};
    --lp-color-primary-dark: #{$lp-color-primary-dark};
    --lp-color-primary-item: #{$lp-color-primary-item};
    --lp-color-primary-item-fade: #{linear-gradient(
            to right,
            rgba($lp-color-negative-bg, 0.95),
            rgba($lp-color-negative-bg, 0.707)
        )};
    --lp-color-primary-faded: #{$lp-color-primary-faded};
    --lp-color-light: #{$lp-color-light};
    --lp-color-gray: #{$lp-color-gray};
    --lp-color-button: #{$lp-color-button};
    --lp-color-action-1: #{$lp-color-action-1};
    --lp-color-action-2: #{$lp-color-action-2};
    --lp-color-action-3: #{$lp-color-action-3};
    --lp-color-action-3-light: #{$lp-color-action-3-light};
    --lp-color-text-1: #{$lp-color-text-1};
    --lp-color-text-2: #{$lp-color-text-2};
    --lp-color-text-3: #{$lp-color-text-3};
    --lp-color-text-4: #{$lp-color-text-4};
    --lp-color-text-black: #{$lp-color-text-black};
    --lp-color-cool: #{$lp-color-cool};
    --lp-color-cool-2: #{$lp-color-cool-2};
    --lp-color-favorite: #{$lp-color-favorite};
    --lp-color-twitter: #{$lp-color-twitter};
    --lp-color-google: #{$lp-color-google};
    --lp-color-apple: #{$lp-color-apple};
    --lp-color-vimeo: #{$lp-color-vimeo};
    --lp-color-facebook: #{$lp-color-facebook};
    --lp-color-aubergine: #{$lp-color-aubergine};
    --lp-color-card-bg: #{$lp-color-card-bg};
    --lp-color-card-bg2: #{$lp-color-card-bg2};
    --lp-color-primary-text-element: #{$lp-color-primary-text-element};
    --lp-color-primary-text-chat: #{$lp-color-primary-text-chat};
    --lp-color-primary-alert: #{$lp-color-primary-alert};
    --lp-color-bg2: #{$lp-color-bg2};
    --lp-color-selected: #{$lp-color-selected};
    --lp-color-bg: #{$lp-color-bg};
    --lp-color-negative-bg: #{$lp-color-negative-bg};
    --lp-color-text-overlay-bg: #{$lp-color-text-overlay-bg};
    --lp-color-text-overlay: #{$lp-color-text-overlay};
    --lp-color-bgcontainer: #{$lp-color-bgcontainer};
    --lp-color-filter-1: #{$lp-color-filter-1};
    --lp-color-filter-2: #{$lp-color-filter-2};
    --lp-color-filter-3: #{$lp-color-filter-3};
    --lp-color-filter-4: #{$lp-color-filter-4};
    --lp-color-danger: #{$lp-color-danger};
    --lp-color-light-text: #{$lp-color-light-text};
    --lp-color-list-item-color: #{$lp-color-list-item-color};
    --lp-color-primary-text-secure: #{$lp-color-primary-text-secure};
    --lp-color-orange-favorite: #{$lp-color-orange-favorite};
    --lp-color-alternate: #{$lp-color-alternate};
    --lp-color-primary-70: #{rgba($lp-color-primary, 0.7)};
    --lp-color-alternate-70: #{rgba($lp-color-alternate, 0.7)};
    --lp-color-primary-20: #{rgba($lp-color-primary, 0.2)};
    --lp-color-action-3-70: #{rgba($lp-color-action-3, 0.7)};

    // --lp-transparent-modal-bg: #{rgba($lp-color-text-3, 0.2)};

    --lp-transparent-modal-bg: #{rgba($lp-color-primary, 0.7)};
    --lp-color-toolbar: #{$lp-color-toolbar};
    --lp-color-toolbar-semi: #{rgba($lp-color-toolbar, 0.9)};
    --lp-color-toolbar-alternate-semi: #{rgba($lp-color-toolbar-alternate, 0.9)};
    --lp-color-toolbar-warn-semi: #{rgba($lp-color-toolbar-warn, 0.9)};
    --lp-color-toolbar-faded: #{$lp-color-toolbar-faded};
    --button-grad-1: 0.8;
    --button-grad-2: 0.6;
    --background-radial-gradient-matte: #{radial-gradient($lp-color-negative-bg, $lp-color-bg)};
    --background-radial-gradient-matte-dark: #{radial-gradient($lp-color-bg-dark, $lp-color-bg-dark2)};
    --background-radial-gradient: #{radial-gradient(rgba($lp-color-negative-bg, 0.2), rgba($lp-color-negative-bg, 0.6))};
    --background-radial-gradient-menu: #{radial-gradient(
            rgba($lp-color-negative-bg, 0.707),
            rgba($lp-color-negative-bg, 0.95)
        )};
    --background-linear-gradient-menu: #{linear-gradient(
            to right,
            rgba($lp-color-negative-bg, 0.95),
            rgba($lp-color-negative-bg, 0.707)
        )};

    // --background-linear-gradient-menu: #{linear-gradient(to right, rgba($lp-color-negative-bg, 0.7), rgba($lp-color-negative-bg, 0.4))};
    --text-overlay-radial-gradient: #{radial-gradient(
            rgba($lp-color-text-overlay-bg, var(--button-grad-1)),
            rgba($lp-color-text-overlay-bg, var(--button-grad-2))
        )};
    --lp-border-width: #{$border-width-raw * 1px};
    --lp-border-width-thin: 2px;
    --border-radius-inner-global-raw: #{$border-radius-global-raw} - #{$border-width-raw};
    --border-radius-outer-global: #{($border-radius-global-raw + $border-width-raw) * 1px};
    --border-radius-global: #{$border-radius-global-raw * 1px};
    --border-radius-global-button: #{$border-radius-global-button-raw * 1px};
    --border-radius-toolbar: #{$border-radius-toolbar * 1px};
    --border-radius-inner-global: #{$border-radius-inner-global-raw * 1px};
    --border-radius-global-s: #{$border-radius-global-s * 1px};
    --border-radius-sidebar: 40px;
    --border-radius-inner-button: 20px;
    --border-radius-outer-button: 22px;
    --border-radius-inner-overlay: 20px;
    --leplace-status-bar-height: 30px;
    --leplace-progress-bar-height: 28px;
    --leplace-status-bar-height-ios: 32px;
    --leplace-progress-bar-height-ios: 34px;
    // --lp-border-color: #{rgba($lp-color-action-3, 0.5)};
    // --lp-border-color-high: #{$lp-color-action-3};
    // --lp-border-color-low: #{rgba($lp-color-light, 0.5)};
    --lp-border-color: #{rgba($lp-color-primary, 0.5)};
    --lp-border-color-high: #{$lp-color-primary};
    --lp-border-color-low: #{rgba($lp-color-light, 0.5)};

    // --lp-border-color-item: #{rgba($lp-color-action-3, 0.5)};
    --lp-border-color-item: #{rgba($lp-color-primary, 0.5)};
    --lp-color-p-1: #99c1de;
    --lp-color-p-2: #dbe7e4;
    --lp-color-p-3: #eddcd2;
    --lp-color-p-4: #bcd4e6;
    --lp-color-p-5: #f0efeb;
    --lp-color-p-6: #c5dedd;
    --lp-color-p-7: #d6e2e9;
    --lp-color-p-8: #fad2e1;
    --lp-color-p-9: #fde2e4;
    --lp-color-p-10: #fff1e6;
    --font-size-xxl: clamp(10px, 8vmin, 36px);
    --font-size-xl: clamp(10px, 5.5vmin, 26px);
    --font-size-lg: clamp(10px, 5vmin, 24px);
    --font-size-mlg: clamp(10px, 4.5vmin, 20px);
    --font-size-m: clamp(10px, 4vmin, 18px);
    --font-size-s: clamp(10px, 3.5vmin, 16px); // reference
    --font-size-xs: clamp(10px, 3vmin, 14px);
    --font-size-xxs: clamp(10px, 2.5vmin, 12px);

    // --font-size-s2: clamp(12px, 2.5vmin, 16px);

    --font-size-logo: clamp(10px, 8vmin, 32px);
    --font-size-title-m: clamp(10px, 4.5vmin, 22px);
    --font-size-title-s: clamp(10px, 4vmin, 18px);
    --font-size-title-xs: clamp(10px, 3.5vmin, 16px);
    --font-size-heading-item: clamp(10px, 8vmin, 36px);
    --font-size-counter: clamp(10px, 5vmin, 24px);
    --button-font-size-xs: clamp(10px, 3vmin, 14px);
    --button-font-size-s: clamp(10px, 3.5vmin, 16px);
    --button-font-size-m: clamp(10px, 4vmin, 18px);
    --button-font-size-mlg: clamp(10px, 4.5vmin, 20px);
    --button-font-size-lg: clamp(10px, 5vmin, 22px);
    --transparent: rgba(0, 0, 0, 0);
    --hud-chart-border-color: #{rgba($hud-bgcolor, $hud-opacity) !important};
    --hud-chart-border-color-debug: #{rgba($hud-bgcolor, $hud-opacity-debug) !important};
    --hud-chart-axis-border: #{($hud-line-width-inner * 1px) dashed rgba($hud-bgcolor, $hud-opacity)};
    --hud-line-width-inner: #{$hud-line-width-inner * 1px};
    --hud-bgcolor: #{$hud-bgcolor};
    --gmap-hud-color: #{rgba($lp-color-primary, 0.8)};
    --gmap-hud-bgcolor: #{rgba($lp-color-primary, 0.2)};

    // --border-color: blue;
    --lp-border-style: solid;
    --lp-border-style-item: solid;
    --lp-border-style-button: solid;
    --border-outset-button: var(--lp-border-width);
    --lp-item-padding: 12px;
    --lp-list-item-padding: 4px;
    --lp-first-item-padding: 6px;

    // --border-style: none;
    // --border-style-item: none;

    // App iOS Variables
    // --------------------------------------------------
    // iOS only Sass variables can go here

    // App Material Design Variables
    // --------------------------------------------------
    // Material Design only Sass variables can go here
    --toolbar-md-background: var(--lp-color-primary);

    // Change the color of the segment button text
    --toolbar-md-active-color: #fff;
    --popover-md-border-radius: 20;
    --list-md-border-color: var(--lp-color-light);

    // Material Design only Sass variables can go here
    --toolbar-ios-background: var(--lp-color-primary);

    // Change the color of the segment button text
    --toolbar-ios-active-color: #fff;
    --popover-ios-border-radius: 20;
    --list-ios-border-color: var(--lp-color-light);
    --action-button-height: 44px;
    --ion-font-family: "leplace";
}

body {
    font-family: "leplace";
}
