.paper-card {
    box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.thin-col {
    // text-align: center;
    // align-items: center;
    // justify-content: center;
    // flex-direction: row;
    // display: flex;
    flex: 0 0 4px;

    // flex-grow, flex-shrink, flex-basis
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
}

.scroll-fab-h {
    padding: 15px;
}

.progress-bar-filler {
    min-height: 8px;
    background: var(--ion-toolbar-background);
}

.height-none {
    height: 0px;
}

.progress-bar-s {
    height: 4px;
}

.progress-bar-filler-s {
    height: 4px;
    background: var(--ion-toolbar-background);

    // min-height: 4px;
}

.progress-bar-filler-s-transparent {
    height: 4px;
    background: rgba(0, 0, 0, 0);
}

.progress-bar-padding-bottom {
    padding-bottom: 8px;
}

.text-box {
    background: rgba(0, 0, 0, 0);
    text-align: center;
    border-radius: var(--border-radius-global);
    border-color: var(--lp-border-color-item);
}

.border-radius-frame {
    border-radius: var(--border-radius-global);
}

// ion-icon {
//     font-size: 40px; //Preferred size here
// }

.custom-searchbar {
}

.custom-checkbox {
    --background: rgba(0, 0, 0, 0);
    --background-checked: rgba(0, 0, 0, 0);
    --border-color-checked: var(--lp-color-primary-text) !important;
    --border-color: #{rgba($lp-color-primary-text, 0.5) !important};
    --checkmark-color: var(--lp-color-primary-text) !important;

    // deprecated
    .checkbox-icon {
        background-color: rgba(0, 0, 0, 0);

        .checkbox-inner {
            color: var(--lp-color-primary-text) !important;
        }
    }

    .checkbox-checked {
        border-color: rgba(var(--lp-color-primary-text), 0.5) !important;

        .checkbox-inner {
            border-color: var(--lp-color-primary-text) !important;
        }
    }
}

.checkbox-large {
    width: 30px;
    height: 30px;
}

.checkbox-right {
    margin-left: auto;
}

.radio-center {
    width: 44px;
    margin: auto;
}

.custom-radio {
    --color: var(--lp-color-primary-text) !important;
    --color-checked: #{rgba($lp-color-primary-text, 0.5) !important};

    // deprecated
    .radio-icon {
        background-color: rgba(0, 0, 0, 0);

        .radio-inner {
            color: var(--lp-color-primary-text) !important;
        }
    }

    .radio-checked {
        border-color: rgba(var(--lp-color-primary-text), 0.5) !important;

        .radio-inner {
            background-color: var(--lp-color-primary-text) !important;
        }
    }
}

.vertical-align-content > * {
    display: flex !important;

    //  align-content: center!important;
    //  align-items: center!important;
    justify-content: center !important;
}

.qr-code {
    text-align: center;

    img {
        border-radius: var(--border-radius-global) !important;

        // width: 200px;
    }

    canvas {
        border-radius: var(--border-radius-global) !important;

        // width: 200px;
    }
}

.shift-view {
    // extreme case, after showing video ads
    top: 20px;
}

.shift-toolbar {
}

.shift-toolbar:first-child {
    padding-top: 0px !important;
}

.custom-picker {
    .picker-wrapper {
        border-top-left-radius: var(--border-radius-global);
        border-top-right-radius: var(--border-radius-global);
    }

    .picker-button {
        color: var(--lp-color-light);
    }

    .picker-opt.picker-opt-selected {
        color: var(--lp-color-light);
    }
}

.ion-calendar-custom {
    border-radius: var(--border-radius-global) !important;
}

.ionic-selectable-custom-wrapper {
    .ionic-selectable-has-placeholder .ionic-selectable-value-item {
        color: var(--lp-color-primary-text) !important;
        opacity: 0.5; /* Firefox */
    }
    .ionic-selectable-icon-inner {
        color: var(--lp-color-primary-text) !important;
    }
}

.ionic-selectable-modal-md,
.ionic-selectable-modal-ios {
    position: absolute;
    overflow: hidden;

    ion-content {
        --overflow: auto !important;
        // hide ugly scrollbar
        right: -20px;
    }

    height: 60vh !important; // this for fixing button not showing in landscape mode
    max-height: 60vh !important; //

    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    // h-offset, v-offset blur spread color
    // box-shadow: 0px 16px 20px 0px var(--shadow-color);
    // -webkit-box-shadow: 0px 16px 20px 0px var(--shadow-color);
    border-style: solid;
    border-color: var(--lp-border-color);
    border-radius: var(--border-radius-global);
    border-width: var(--lp-border-width);

    .searchbar-input-container {
        color: var(--lp-color-primary-text);
        border-bottom: var(--lp-border-width) solid var(--lp-color-primary-item);
        .sc-ion-searchbar-md {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .searchbar-search-icon {
        color: var(--lp-color-primary-text);
    }

    ion-icon {
        color: var(--lp-color-primary-text);
    }
}

.text-selectable {
    user-select: text !important;
}

.carousel-center-size {
    max-width: 50vh;
    margin-left: auto;
    margin-right: auto;
}
